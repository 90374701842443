import { useContext } from "react"
import Typography from "@mui/material/Typography"
import {Stack} from "@mui/material"
import DigitalTwinContext from "./DigitalTwinContext"
import { UploadGeodata } from "../widgets/UploadGeodata"


const UploadInfiltrationPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)

    const handleUpload = (event) => {
        setParams({...params, ir: event.filename})
    }
    
    const handleFieldChange = (fieldName) => {
        setParams({...params, ir_fname: fieldName})
    }

    return (<>
        <Stack spacing={2}>
          <Typography>Choose an infiltration vector file (.shp) from your device</Typography>
          
          <UploadGeodata 
                cmap = "infiltration_rate"
                onUpload={handleUpload} 
                onChangeField={handleFieldChange}
                geometry_type={["polygon"]}
                mandatory_fields={["PERM"]}
                datatype="vector"
                fieldSelector
          />
          {/* <Typography variant="caption"> {localPath ? "File uploaded: "+localPath : "no file selected" }</Typography> */}
        </Stack>
    </>)
}
export default UploadInfiltrationPanel;