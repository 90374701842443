import { useContext } from "react"
import Typography from "@mui/material/Typography"
import {Stack} from "@mui/material"
import DigitalTwinContext from "./DigitalTwinContext"
import { UploadGeodata } from "../widgets/UploadGeodata"


const UploadBuildingsPanel = () => {
    
    const [params, setParams] = useContext(DigitalTwinContext)

    const handleUpload = (event) => {
        //console.log(event)
        setParams({...params, buildings: event.filename})
    }

    return (<>
        <Stack spacing={2}>
          <Typography>Choose a buildings vector file (.shp) from your device</Typography>
          
          <UploadGeodata
                datatype="vector"
                geometry_type="polygon"
                cmap="buildings"
                onUpload={handleUpload}
            />
          {/* <Typography variant="caption"> {params.buildings ? "File uploaded: "+params.buildings : "no file selected" }</Typography> */}
        </Stack>
    </>)
}
export default UploadBuildingsPanel;