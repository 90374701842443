import { useContext} from "react"
import Typography from "@mui/material/Typography"
import { Stack} from "@mui/material"
import DigitalTwinContext from "./DigitalTwinContext"
import { UploadGeodata } from "../widgets/UploadGeodata"


const UploadDTMPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)

    const handleUpload = (event) => {
        setParams({...params, dtm: event.filename, t_srs: event.t_srs.authid})
    }

    return (<>
        <Stack spacing={2}>
          <Typography>Choose a DTM raster file (.tif) from your device</Typography>
          
            <UploadGeodata
                onUpload={handleUpload} 
                cmap = "dtm"
                datatype = "raster"
          />
        </Stack>
    </>)
}
export default UploadDTMPanel;