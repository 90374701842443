import React from "react";
import Box from "@mui/material/Box"
import { StatusMessageBox } from "./StatusMessageBox";
import {footerBarHeight, zIndex} from "../themes"

export default function Footer() {
    return (
      <Box
        style={{
          background: "linear-gradient(to right,#534CF7,#80E9A6)",
          borderRadius: "0px 0px 25px 25px",
          height: {footerBarHeight},
          width: "100%",
          position: "fixed",
          bottom: 0,
          left:0,
          right:0,
          zIndex: zIndex.footer,
        }}
      >
        <StatusMessageBox  />
      </Box>
    );
}