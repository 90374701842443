import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useInterval } from "../../utils/useinterval"
import { Stack } from "@mui/system";
import { getResourcesUsage } from "../../utils/requests";


export default function ResourcesLabel() {
      
    const [status, setStatus] = useState({"cpu":0, "ram":0})
    
    useInterval(()=>{
        getResourcesUsage().then(response=>{
          if (response && response.status===200){
             setStatus({...response.data.data}) 
          }
        })
    }, 2000)

    return (
      <Stack>
          <Typography fontSize={12}>CPU: {status.cpu}%</Typography>
          <Typography fontSize={12}> RAM: {status.ram.toFixed(0)}MB</Typography>
      </Stack>
    )
}