import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import UploadBuildingsPanel from "./UploadBuildingsPanel"
import DownloadBuildingsPanel from "./DownloadBuildingsPanel"

const BuildingsStep = () => {

  const [mode, setMode] = useState("download")

  const handleChange = (event) => {
    setMode(event.target.value)
  };


  return (
    <FormControl>
      <RadioGroup row onChange={handleChange} defaultValue="download">
        <FormControlLabel value="download" control={<Radio />} label="Download"  />
        <FormControlLabel value="upload"   control={<Radio />} label="Upload" />
      
        { 
          (mode === "download") ?
            <DownloadBuildingsPanel/> 
            :
            <UploadBuildingsPanel />
        }

      </RadioGroup>
    </FormControl>
  );
}
export default BuildingsStep;
