
//import HubspotForm from 'react-hubspot-form'
import React from 'react';
import {Grid, Button, Box} from "@mui/material"
import { signinBoxStyle } from '../themes';
import {useNavigate} from "react-router-dom"
import Paths from '../utils/paths';
import SubscribeFrom from './SubscribeForm';


function RegistrationPage(props){
    
    let navigate = useNavigate()

    const handleBack = () => {
        navigate(Paths.LOGIN)
    }

    return (
        <>
        <Box style={signinBoxStyle}>
            <Grid item>
                {/* <HubspotForm
                    width="50%"
                    region= "eu1"
                    portalId="25714549"
                    formId="e050b843-7fa3-40f9-8a3f-5fac9e445fd3"
                    loading={<div>Loading...</div>} >
                </HubspotForm> */}
                <SubscribeFrom/>

            </Grid>
            <Grid item>
                <Button onClick={handleBack}> Back </Button>
                <br/><br/><br/>
            </Grid>
            
        </Box>
        
        </>
        )   
        
    }
    
    export default RegistrationPage;