import React from "react";
import {Button, Stack} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

export const CreateNewProjectButton = (props) => {

    return (
        <Button 
            onClick={props.onClick}
            sx={{minWidth:"100%", minHeight:"100%", boxShadow:0}}>
            <Stack spacing={1}>
                <AddRoundedIcon sx={{ fontSize: 150, shadow:3 }}/>
                <h3> Create project </h3>
            </Stack>
        </Button>
        // <Button 
        //     onClick={props.onClick}
        //     sx={{minWidth:"100%", minHeight:"100%", boxShadow:3}}>
        //         <h2> New project </h2>
        // </Button>
        
      );
}