import { createTheme } from '@mui/material/styles';

// colors
const SP_production = "#524cf7"
const SP_Blue_Hover = "#645eff"
const SP_white = "#fffeff"
const SP_green = "#138535"

//const SP_production= "#eb5f07" orange
// appbar
export const drawerWidth = 500;
export const appbarHeight = 50;
export const heaederBarHeight = 20;
export const footerBarHeight = 20;

// left-right panel
export const LeftPanelWidth = 400
export const RightPanelWidth = 300

export const loginBoxStyle = {
    margin: "10px auto", 
    height: "500px", 
    width: "40%", 
    padding:"50px", 
    aling:"center",
};

export const signinBoxStyle = {
    margin: "10px auto", 
    height: "500px", 
    width: "40%", 
    padding:"50px", 
    aling:"center",
};

export const buttonStyle = {
    margin: "20px auto",
}

export const SPLightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        //light: 'transparent',
        main: (process.env.NODE_ENV==="development") ? SP_green : SP_production,
        dark: SP_Blue_Hover,
        contrastText: '#fff',
        backgroundColor:"black"
      },
      secondary: {
        //light: '#eeeeee',
        main: SP_white,
        dark: '#aaaaaa',
        contrastText: '#000',
      },
    },
  });

  export const statusMessageStyle_success = {
      color: "#0f0",
      paddingLeft:"20px", 
  }

  export const statusMessageStyle_info = {
    color: SP_white,
    paddingLeft:"20px", 
}

  export const statusMessageStyle_err = {
    color: "#f00",
    paddingLeft:"20px", 
}

export const zIndex = {
  footer: 1501,
  header: 1501,
  drawer: 1500,
  chartBox: 2001,
  dialog: 2100, 
}
