import {
  Input, 
  InputAdornment, 
  DialogContentText, 
  FormHelperText,
  DialogContent,
  Grid
} from "@mui/material"



export const ExtremeSeaLevelStep = function ({scenario, setScenario}) {

    const handleEslChange = (event) => {
      setScenario({...scenario, esl: event.target.value });
      return true;
    };
  
    return (
      <>
      <DialogContent>

        <DialogContentText component="div">
          This is the sea water level
        </DialogContentText>

        <Grid container spacing={2}>
        
          <Grid item lg={6}>
            <Input
              value = {scenario.esl}
              textalign={'right'}
              type="number"
              endAdornment={<InputAdornment position="end">m</InputAdornment>}
              inputProps={
                {style:{textAlign:"right"}, 
                step:0.05,
                min: 0.0,
                max: 10.0
              }}
              onChange={handleEslChange}
            />
            <FormHelperText>sea level</FormHelperText>
          </Grid>

        </Grid>
      </DialogContent>
      
      </>
    );
  };
  
  
  
  