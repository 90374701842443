import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { hugevalue } from "../../utils/currency";



/**
 * Recieves a list of series and plot them as bars diagram. On the x axis there is the water level and on the y axis there is the damage.
 * @param {*} series Series of list of series. Each series is a list of y values. Example: 
 *                   ```[[0.1, 0.2, 0.3], [0.4, 0.5, 0.6]]``` or ```[0.1, 0.2, 0.3]```
 * @param {*} categories List of water levels. 
 * @param {int} maxY Max value of the y axis which will be fixed to that level. If null, it is computed automatically.
 * @returns 
 */
export const DamageBarChart = ({series, seriesNames, title="", xAxisTitle="", yAxisTitle="", categories, color, maxY=null} ) => {

    if (!series || !categories || !series.length || !categories.length) {
        series = []
    }

    if (!seriesNames) {
        seriesNames = series.map((s, i) => `Series ${i+1}`)
    }

    series = (series[0] instanceof Array) ? series : [series] // if the series is a list of series, then it is already a list of lists. Otherwise, it is a list of values and we need to wrap it in a list.
    series = series.map((s,i) => {return {data: s, color: color, name: seriesNames[i]}})


    const options = {
        chart: {
            type: 'column', 
            width: 200,
            height: 200,
            
        },
        xAxis: {
            labels: {
                enabled: true
            },
            categories: categories,
            title: {
                text: xAxisTitle
            }
        },
        yAxis: {
            title: {
                text: yAxisTitle
            },
            labels: {
                enabled: true
            },
            min: 0,
            max: maxY,
            startOnTick: true,
            endOnTick: true,
            tickAmount: 3 //maxY < 10 ? maxY : 10,
        },

        legend: {
            enabled: false
        },

        title: {
            text: ""//title
        },
        subtitle: {
            text: title
        },
         
        tooltip: {
            
            formatter: function() {
                let wl = this.x,
                    dmg = this.y,
                    name = this.points[0].series.name
          
                return `<table>
                        <tr> <td>${name}</td> </tr>
                        <tr>
                            <td>Water level</td>            <td style="text-align:right"> ${wl} m </td> </tr>
                            <td><b>Damage preview</b></td>  <td style="text-align:right"> <b> ${hugevalue(dmg)} $ </b> </td> </tr>
                        </table>`
              },
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            }
        },
        series: series, 
        credits: {
            enabled: false
        }

    }

    return <HighchartsReact highcharts={Highcharts} options={options} style={{border: "1px solid black"}}/> 
        
}