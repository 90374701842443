import React from 'react';
import Box from "@mui/material/Box"
import { fireEvent, } from '../utils/events';
import {Grid} from "@mui/material"
import {Button} from "@mui/material"
import {  zIndex } from '../themes';

export default function ChartBox({children, sx, hidden}) {
    
    const handleClose = () => {
      fireEvent("section:close") // this component is not in charge of opening and closing the box, but only to render it. The closure command
    }

    // const [points, setPoints] = useState([])
    // const [index, setIndex] = useState(-1)

    // const changeIndex = (event) => {
    //   let newIndex = event.target.index || event.target.value
    //   setIndex(newIndex)
    //   //! console.log("[changeIndex]\n  new index", newIndex, "\n  point: ", points[newIndex]) <--- qui points risulta vuoto. Nella useEffect invece, funziona. 
    // }

    // useEvent("section:drawend", (data) => {
    //   setPoints(data.detail.points)
    // })

    // useEffect(() => {
    //   console.log("[useEffect]\n  new index", index, "\n  point: ", points[index])
    // }, [index])


   return (
        <>        

          {/* <Box position="fixed" color="secondary" sx={{ top: 'auto', bottom: "100px ", height:300, width:"100%"}}>
            {children}
          </Box> */}

      
      <Box
        style={{
          background: "white",
          borderRadius: "0px 0px 25px 25px",
          boxShadow: 12,
          height: "275px",
          right: 0, 
          left: 0,
          width: "100%",
          position: "fixed",
          bottom: 0,
          zIndex: zIndex.chartBox,
          border:"1px solid black"
        }}
      >
        <Grid container direction="row">
          
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}>
                X
            </Button>
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>

         

        </Grid>
        
      </Box>
          
    
        </>
      );
    
  }
  