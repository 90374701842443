import { juststem } from "./filesystem"

const predefined_job = "(WD_|DMG_)?(?<scenario>P|R|C)(?<rt>\\d+)(?<proj>H|P1|P2)"


/**
 * isJobPredefined
 * @param {*} filename 
 * @returns 
 */
export const isJobPredefined = (filename)=>{ //! Fuorviante: Questa non controlla i job ma i file delle simulazioni

    let name = juststem(filename);
    //let re = new RegExp("^(WD_|DMG_)?(P|R|C)(\\d+)(H|P1|P2)", "i");
    let re = new RegExp(predefined_job, "i");
    return re.test(name);

}


/**
 * getJobScenario
 * @param {*} filename 
 * @returns 
 */
export const getJobScenario = ( filename )=>{
    const scenarios = {"P":"Rain","R":"River","C":"Coast"}
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?scenarios[res.groups["scenario"]]:"";
}

/**
 * getJobReturnTime
 * @param {*} filename 
 * @returns 
 */
export const getJobReturnTime = ( filename )=>{    
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?res.groups["rt"]:"";
}

/**
 * getJobProjection
 * @param {*} filename 
 * @returns 
 */
export const getJobProjection = ( filename )=>{   
    const projections = {"H":"Historical","P1":"RCP4.5","P2":"RCP8.5"} 
    let name = juststem(filename);
    let re = new RegExp(predefined_job, "i");
    let res = re.exec(name);
    return (res)?projections[res.groups["proj"]]:"";
}



export const test = ()=>{

    let filenames = [
        "P2H",
        "WD_P5H.tif",
        "WD_C5P1.tif",
        "WD_R100P2.tif",
        "DMG_P5H.tif", 
        "DMG_A5H.tif" //NOT OK
    ];
    for (let filename of filenames){
        console.log(filename,isJobPredefined(filename));
        console.log(getJobScenario(filename), getJobReturnTime(filename), getJobProjection(filename));
    } 

}
