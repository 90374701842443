import React from "react";
import subscribeFormText from "./subscribe.html";

function SubscribeFrom() {

    let SaferPlacesLogo = "https://mcusercontent.com/e8a84e36e6d6bc0a0a52216e1/images/35417879-a10d-e50d-985d-c9d4ed67c709.png"

  return (
    
    <div>
        <img src={SaferPlacesLogo} alt="Saferplaces Logo" width="600" />
      {/* Embedding HTML */}
      <div dangerouslySetInnerHTML={{ __html: subscribeFormText }}></div>
    </div>
  );
}

export default SubscribeFrom;