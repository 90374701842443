import { 
  DialogContentText, 
  Checkbox, 
  Input, 
  InputAdornment, 
  FormHelperText,
  DialogContent,
  Grid
} from "@mui/material";

export const BeachAngleStep = function ({scenario, setScenario}) {

  const handleCheckBoxChange = (event) => {
    setScenario({...scenario, r: event.target.checked});
    return true;
  };

  const handleTanaChange = (event) => {
    
    setScenario({...scenario, tana: event.target.value});
    return true;
  };

  return (
    <>
    <DialogContent>

      <DialogContentText component="div">
        If enabled this is the slope of the beach that will overwrite <br />
        the DEM where the DEM is lower.
      </DialogContentText>

      <Grid container spacing={2}>
        
        <Grid item lg={6}>
          <Checkbox
            checked={scenario.r}
            onChange={handleCheckBoxChange}
          />

          <Input
            disabled={!scenario.r}
            value = {scenario.tana}
            textalign={'right'}
            type="number"
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            inputProps={{
              min:0.0,
              max:0.1,
              step:0.0005,
              style:{textAlign:"right"}}
            }
            onChange={handleTanaChange}
          />
          <FormHelperText>
            Tan(&alpha;) - mean angle of beach{" "}
          </FormHelperText> 
        </Grid>

      </Grid>
      
    
    </DialogContent>
    </> 
  );
};