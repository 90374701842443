import Username from "./widgets/Username";


const UserSettingsPanel = (props)=>{

    return <>
    
        <Username/>

        <li>change password</li>

        <li>type of license</li>

        <li>expiration date</li>
        
        </>
}
export default UserSettingsPanel;
