import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { QgisContext, QgisProject } from "@valluzzi/safer-map"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { useEvent, fireEvent } from "../utils/events"
import strftime from "strftime"
import { addjob } from "../utils/addjob"
import { justpath } from "../utils/filesystem"
import { __drive__ } from "../utils/const"


export default function Safer001Dialog(props) {

    const [open,  setOpen]  = useState(false)
    const [bdate, setBDate] = useState(dayjs("2022-11-22"))
    const [time_lag, setTimeLag] = useState(1)

    const [project, ] = useContext(QgisContext);
    const Q = new QgisProject(project)
  
    const handleClose = () => {
      setOpen(false)
    }
  
    const handleOpen = () => {
      setOpen(true)
    }

    const handleRun = () => {

        handleClose()

        //const time_lag = edate.diff(bdate, 'days')
        const now = strftime("%H%M%S")
        const filedem = Q.getDemPath(__drive__)
        
        let params = {
            name:        `SF001_${now}`,
            type:        "safer001",
            project:     Q.getProjectName(),
            bbox:        filedem,
            dem:         filedem,
            start:       bdate.format("YYYY-MM-DD"),
            time_lag:    time_lag,
            filesar:     `${justpath(filedem)}/SAR_SF001_${now}.tif`,
            fileopt:     `${justpath(filedem)}/OPT_SF001_${now}.tif`,
            filegfm:     `${justpath(filedem)}/GFM_SF001_${now}.tif`,
            note:        `Safer001`,
            mode:        "local"
        }
        fireEvent("simulation:start")
        addjob(params)
    }

    const handleBDateChange = (date) => {
        setBDate(date)
    }

    const handleTimeLagChange = (event) => {
        setTimeLag(Number(event.target.value))
    }

    useEvent("safer001:open", handleOpen)

    return (
        <>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" >
            <DialogTitle>Safer001</DialogTitle>
            <DialogContent style={{ paddingTop: "50px"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    
                <DatePicker 
                    label="from date"
                    format="DD/MM/YYYY" // Set your desired date format here
                    value={bdate} 
                    onChange = {handleBDateChange}
                />
                <FormControl variant="outlined" sx={{ width: "100px" }}>
                    <InputLabel id="time-lag-label">lag (days)</InputLabel>
                    <Select
                        labelId="time-lag-label"
                        variant="outlined"
                        label="lag (days)"
                        value={time_lag}
                        onChange={handleTimeLagChange}
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
                    
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                {/* BUTTON CANCEL*/}
                <Button onClick={handleClose} color="error">Cancel</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                    
                    onClick={handleRun} 
                    color="inherit">
                    Run
                </Button>
            </DialogActions>
          </Dialog>
      </>)
      
}