import React, { useContext, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import UploadDTMPanel from "./UploadDTMPanel"
import DownloadDTMPanel from "./DownloadDTMPanel"
import DigitalTwinContext from './DigitalTwinContext';
import { fireEvent } from '../utils/events';

const DtmStep = () => {

  const [mode, setMode] = useState("download")
  const [params, setParams] = useContext(DigitalTwinContext)
  
  /** Switch dtm mode between upload and download */ 
  const handleModeChange = (event) => {
    let mode = event.target.value
    setMode(mode)
    if (mode === "upload"){
      setParams({...params, bbox:[]}) // svuotata bbox
    }
    fireEvent("toggleDrawingTool", false) // disattiva drawing tool
  };


  return (
    <FormControl>
      <RadioGroup row onChange={handleModeChange} defaultValue="download">
        <FormControlLabel value="download" control={<Radio />} label="Download"  />
        <FormControlLabel value="upload"   control={<Radio />} label="Upload" />
        { 
          (mode === "download") ?
            <DownloadDTMPanel />
            :
            <UploadDTMPanel />
        }

      </RadioGroup>
    </FormControl>
  );
}
export default DtmStep;
