//export const __drive__ = "/mnt/s3/saferplaces.co"
export const __drive__ = "/mnt/efs"

export const getToken = () => sessionStorage.getItem("token")

export const getUserName = () => sessionStorage.getItem("username")

export const getProjectName = () => sessionStorage.getItem("projectname")


