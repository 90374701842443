import React  from 'react'
import { 
    Box,
    Typography, 
    Input, 
    FormHelperText,
    FormControl, 
    InputAdornment, 
    FormLabel, 
    FormControlLabel, 
    RadioGroup, 
    Radio,
    FormGroup,
    Checkbox, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    DialogContent, 
    DialogContentText,
} from '@mui/material' 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings'; 


/*************************************************************************************************************
 *     4)   ModelStep                                                                                        *
 *************************************************************************************************************/
 export const ModelStep = function({scenario, setScenario}) {

    const handleModelChange = (evt) => {
        setScenario({...scenario, model:evt.target.value } )
    }

    const handleModeChange = (evt) => {
        setScenario({...scenario, mode:evt.target.value } )
    }

    const handleDamageChange = (evt) => {
        setScenario({...scenario, dmg:evt.target.checked } )
    }
    
    const handleTmaxChange = (evt) => {
        setScenario({...scenario, tmax:evt.target.value } )
    }

    const handleNLChange = (evt) => {
        setScenario({...scenario, nl:evt.target.value } )
    }

    
    
    
    return <>
            <DialogContent>
                <DialogContentText component="div">
                    Select a model for the simulation
                </DialogContentText>         
                
                <FormControl>
                    <FormLabel></FormLabel>
                        <RadioGroup 
                            row
                            value={scenario.model}
                            onChange={handleModelChange}
                        >

                            {/* SAFERRAIN MODEL */}
                            <FormControlLabel control={
                                <Radio value="safer_rain"/>
                            } label="SaferPlaces" />

                            {/* UNTRIM MODEL */}
                            <FormControlLabel control={
                                // <Radio value="untrim" disabled/>
                                <Radio value="untrim"/>
                            } label="UnTrim"/>

                    </RadioGroup>
                </FormControl>    
            
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <SettingsIcon/>
                        <Typography>Settings</Typography>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                        <div hidden={scenario.model !== "untrim"}>  
                           
                            <Typography>
                                Tmax - Max time of simulation
                            </Typography>
                            <Input
                                onChange={handleTmaxChange}
                                type="number"
                                endAdornment={<InputAdornment position="end">h</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*'}}
                                sx = {{width:80}}
                                value={scenario.tmax}
                            />
                            <FormHelperText id="standard-weight-helper-text">tmax (h)</FormHelperText>
                        </div>

                        <div hidden={scenario.model !== "untrim"}>  
                            <Typography>
                                nl - The number of pixel for each element side (cell = nl x nl pixels) 
                            </Typography>
                            <Input
                                onChange={handleNLChange}
                                type="number"
                                endAdornment={<InputAdornment position="end">px</InputAdornment>}
                                inputProps={{'aria-label': 'weight', inputMode: 'numeric', pattern: '[0-9]*', min:2, max:100}}
                                sx = {{width:80}}
                                value={scenario.nl}
                            />
                            <FormHelperText>nl</FormHelperText>
                        </div>

                        

                        <Typography>Execution Environment (Just for DEBUG)</Typography>
                            
                        <RadioGroup
                            row
                            value={scenario.mode}
                            onChange={handleModeChange}
                        >
                            <FormControlLabel value="local" control={<Radio />} label="local" />
                            <div hidden={scenario.model==="untrim"}><FormControlLabel value="lambda" control={<Radio />} label="lambda" /></div>
                            {/* <FormControlLabel value="batch" control={<Radio />} label="batch" /> */}
                            <FormControlLabel value="ecs" control={<Radio />} label="ecs" />
                        </RadioGroup>

                    </AccordionDetails>
                </Accordion>   

                <Box sx={{ m: 10 }} /> 

                <DialogContentText component="div">
                    Select the damage model
                </DialogContentText>         
                
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={scenario.dmg} onChange={handleDamageChange} />} label="Apply damage" />
                </FormGroup>
        </DialogContent>
    </>
 }

