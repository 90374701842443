import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { fireEvent, useEvent } from '../utils/events';
import { renameProject } from '../utils/requests';

export default function RenameDialog() {

    const [oldName, setOldName] = useState("")
    const [newName, setNewName] = useState("")
    const [open, setOpen] = useState(false);


    /** receive the event for opening the dialog */
    const handleOpenDialog = (event) => {
        setOldName(event.detail)
        setNewName(event.detail)
        setOpen(true) 
    }
    
   
    /** Close the dialog */
    const handleClose = () => {
        setOpen(false);
    }


    /** update the textfield in the dialog */
    const handleTextChange = (event) => {
        let textField = event.target
        setNewName(textField.value)
    }


    /** rename the file, close the dialog*/
    const handleRename = () => {
        renameProject(oldName, newName)
        .then(() => {
            setOldName(newName)
            fireEvent("event:reload")
            handleClose()
        })
        
    }


    useEvent("event:rename", handleOpenDialog)

    return (
       
        <div>
        <Dialog open={open} onClose={handleClose} fullWidth sx={{maxWidth: '50%', margin:'10px auto'}}>
            <DialogTitle> Rename project {oldName}</DialogTitle>
            <DialogContent>
            
            <TextField
                autoFocus
                id="name"
                value={newName}
                type="email"
                fullWidth
                variant="standard"
                onChange={handleTextChange}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">Cancel</Button>
                <Button onClick={handleRename} variant="contained">Confirm</Button>
            </DialogActions>
        </Dialog>

        

        </div>
    );
}