export const strftime = require('strftime')

export const humantime = (mills) => {
    //from mills to s
    let n = Math.round(mills/1000)
    let dd  = Math.floor(n/86400) 
    let HH  = Math.floor( (n - (dd*86400)) /3600) 
    let MM  = Math.floor( (n - (dd*86400) - (HH*3600)) /60)   
    let SS  = n - (dd*86400+HH*3600+MM*60)

    dd = (dd>0)?`${dd}d`:""
    HH = (HH>0)?`${HH}h`:""
    MM = (MM>0)?`${MM}'`:""
    SS = (SS>0)?`${SS}"`:""
    return `${dd}${HH}${MM}${SS}`
}