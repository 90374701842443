
import {Tooltip, Typography} from '@mui/material';

export const BigTooltip = (props)=>{

    if (props.hidden) return null

    return <Tooltip {...props} title={<><Typography>{props.title}</Typography></>}>
      {props.children}
    </Tooltip>
}

