import React, { useContext } from 'react';
import {Typography, Stack, Alert} from "@mui/material"
import EedemButton from "../widgets/EedemButton"
import DigitalTwinContext from './DigitalTwinContext';

const DownloadLithologyPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)
  
    const setJid = (jids) => {
      setParams({...params, lithology_progress: 0, sand_jid: jids[0], clay_jid: jids[1]})
    }

    return (
      <Stack spacing={2}>
          <Typography>Download the layer about sand, clay</Typography>
          {
            params.lithology_error ? 
            <Alert severity="error">{params.dtm_error}</Alert>
            :
            <></>
          }
          <EedemButton 
            dataset={["OpenLandMap/SOL/SOL_SAND-WFRACTION_USDA-3A1A1A_M/v02", "OpenLandMap/SOL/SOL_CLAY-WFRACTION_USDA-3A1A1A_M/v02"]}
            band = {["b10", "b10"]}
            bbox={params.dtm}
            cmap = {["sand", "clay"]}
            progress={params.lithology_progress}
            onClick = {setJid}
          >
            Download
          </EedemButton>
          
        </Stack>
    )
}
export default DownloadLithologyPanel;
  