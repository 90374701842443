import { useState } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/draggable-points")(Highcharts);



export const DamageFunctionChart = ({ seriesName, fixedSeries, customSeries, backgroundSeries, onEditSeries, ...props}) => {
        
    const minY = 0
    const maxY = 100
    
    
    // errore se una delle due serie è nulla, oppure se sono entrambe non vuote e hanno dimensioni diverse
    if (!fixedSeries || !customSeries) {
        console.error(`Series fixedSeries and customSeries must be non null with the same number of points\nfixedSeries: ${fixedSeries ? fixedSeries.length : 0}\ncustomSeries: ${customSeries ? customSeries.length : 0}\n`)
    }
    // è ok se la una delle serie è  vuota, non entrambe
    if (fixedSeries.length !== 0 && customSeries.length !== 0 && fixedSeries.length !== customSeries.length) {
        console.error(`If series fixedSeries and customSeries are non-empyy, then they must have the same number of points\nfixedSeries: ${fixedSeries.length}\ncustomSeries: ${customSeries.length}\n`)
    }
    
    const waterLevels = [0, 0.25, 0.5, .75, 1, 1.25, 1.5, 2.0, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8]

    // implements the mean of two array of coorindate. Each element is a point x,y. 
    // returns an array of x,y where the y is the mean between the two array's y
    const resultFunction = (array1, array2) => {
        let result = []
        for (let i = 0; i < array1.length; i++) {
            result.push({x: array1[i].x, y: (array1[i].y + array2[i].y)/2})
        }
        return result
    }


    let fixedCoords = fixedSeries.map((v, i) => { return {x: waterLevels[i], y: v} })                                     // ? qui veniva aggiunto 1 a X[i]. Non so perché
    let customCoords = customSeries.map((v, i) => { return {x: waterLevels[i], y: v} })                                   // ? qui veniva aggiunto 1 a X[i]. Non so perché
    let backgroundCoords = backgroundSeries ? backgroundSeries.map((v, i) => { return {x: waterLevels[i], y: v} }) : []   // ? qui veniva aggiunto 1 a X[i]. Non so perché
    
    // let resultantCoords = resultFunction(backgroundCoords, customCoords)
    const [resultingCoords, setResultingCoords] = useState(resultFunction(backgroundCoords, customCoords))
    
    const handleDrag = (event) => {
        if (event.target.index === 0) 
            return false
        return true
    }

    const handleDrop = (event) => {
        let index = event.target.index

        if (index === 0) 
            return false
        else {
            let newY = event.newPoint.y
            onEditSeries({index: index, y: newY}) // la onEdit la faccio nella useEffect
            setResultingCoords(resultFunction(backgroundCoords, customCoords))
            return true
        }
    }

    
    const init_options = {
        yAxis: {
            min: minY,
            max: maxY,
            title: {
                text: "Damage (%)"
            }
        },
        xAxis: {
            title: {
                text: "Water depth (m)"
            }
        },
        chart: {
            type:"line",
            animation: false,
            width: 500,
            height: 350
            // border: "lightgray",
            // borderRadius:5,
            // borderWidth:0.5
        },

        title:{
            text: ""
        },

        plotOptions: {
                series: {
                    point:{
                        events: {
                            drag: handleDrag,
                            drop: handleDrop,
                        }
                    },
                    stickyTracking: false,
                    draggablePoints: true,
                    //set the cursor draggable only for the second series
                    cursor: 'ns-resize',

                    // marker: {
                    //     enabled: false,
                    //     states: {
                    //         hover: {
                    //             enabled: true
                    //         }
                    //     }
                    // }
                },
                
        },

        // add the "cm" measure unit to the tooltip
        tooltip: {  
            valueSuffix: ' %',
            shared: true,
            crosshairs: true,
            useHTML: true,
            headerFormat: '<span style="font-size: 10px">Water depth: {point.key} m</span><br/>Damage: <br/>',
            pointFormatter: function() {
                // TODO filtro per nome != da original 
                if (this.series.name === "original") 
                    return ""
                else
                    return `<span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b>${Math.round(this.y,2)} %</b><br/>`
            }
        },
        
        colors: ["lightgray", "red", "purple", "teal"],

        series: [
            // this series is not draggable
            {
                name: 'original',
                data: fixedCoords,
                showInLegend: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },

            // this series is not draggable
            {
                name: 'damage',
                data: backgroundCoords,
                showInLegend: (backgroundCoords && backgroundCoords.length > 0),
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },

            // this series is not draggable
            {
                name: 'resultant',
                data: resultingCoords,
                showInLegend: (resultingCoords && resultingCoords.length > 0),
                states: {
                    hover: {
                        enabled: false
                    }
                }
                
            },

            // this series is draggable but reflects the initial value of the draggable on 
            {
                name: seriesName,
                data: customCoords,
                dragDrop: {
                    draggableY: true,
                    dragMinY: minY,
                    dragMaxY: maxY,
                },
            }

        ],
    }  

    return <HighchartsReact highcharts={Highcharts} options={init_options} style={{border: "1px solid black"}}/>
    
}

