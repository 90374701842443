import React from "react";
import {Box} from "@mui/material" 
import {heaederBarHeight} from "../themes"

export function Header() {

  return (
    <Box 
      style={{
      backgroundColor: "#534CF7",
      borderRadius: "25px 25px 0px 0px",
      height: {heaederBarHeight},
      width: "100%",
      position: "fixed",
      top: 0,
      left:0,
      right:0,
      zIndex: 2000,
    }}>
      &nbsp;
    </Box>
  );
}
