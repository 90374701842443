import React, { useContext } from 'react';
import {Typography, Stack, Alert} from "@mui/material"
import EedemButton from "../widgets/EedemButton"
import DigitalTwinContext from './DigitalTwinContext';

const DownloadBuildingsPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)

    const setJid = (jids) => {
      setParams({...params, buildings_progress: 0, buildings_jid: jids[0]})
    }

    return (
      <Stack spacing={2}>
          <Typography>Download the buildings</Typography>
          {
            params.buildings_error ? 
            <Alert severity="error">{params.dtm_error}</Alert>
            :
            <></>
          }
          <EedemButton 
            dataset="OSM/BUILDINGS"
            band="buildings"
            bbox={params.dtm}
            onClick={setJid}
            progress={params.buildings_progress}
            cmap = "buildings"
          >
            Download
          </EedemButton>
      </Stack>
    )
}
export default DownloadBuildingsPanel;
  