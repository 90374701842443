


const ModelSettingsPanel = (props)=>{

    return <>
        <li>Safer Rain preprocessing options</li>
        <li>Untrim preprocessing options</li>
    </>
}

export default ModelSettingsPanel;