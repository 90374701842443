import React, { useState } from 'react'
import {TextField, InputAdornment} from "@mui/material";


/**
 * NumberField input
 * @param props Properties
 */
export const NumberField = ({onValidate, ...props}) => {

    const [errorText, setErrorText] = useState("")

    const validateValue = (value) => {
        const regexp = /^[-+]?([0-9]*[.])?[0-9]*$/g
        return regexp.test(value) || value ===""
    }

    const validateRange = (value)=>{
        
        if (props.min!==undefined && props.max!==undefined){
            if (value < props.min || value > props.max){
                setErrorText(`Value is not in range [${props.min}-${props.max}]`)
                return false
            }
        }else if (props.min!==undefined && props.max===undefined){
            if (value < props.min){
                setErrorText("Value is too low!")
                return false
            }
        }else if (props.min===undefined && props.max!==undefined){
            if (value > props.max){
                setErrorText("Value is too high!")
                return false
            }
        }
        setErrorText("")
        return true
    }

    const validate = (event) =>{
        if (validateValue(event.target.value) && validateRange(event.target.value)){
            return true
        }else{
            setErrorText("Not a number!")
        }
        return false
    }

    

    return (
        <TextField {...props} 
        
        variant = {props.variant || "standard"}

        type="text"

        inputProps={{
            //inputMode: "numeric",
            //pattern: "[0-9]*",
            style: { textAlign: "right" },
        }}
        
        InputProps={{
            endAdornment: <InputAdornment position="end">{props.um}</InputAdornment>,
        }}
        
        helperText={ (errorText)?errorText:props.description}
        
        error={Boolean(errorText)}
        
        onBlur={(event)=>{
            if (validate(event) && onValidate){
                onValidate(/*{target:{value:value}}*/)
            }
        }}
        
        onKeyUp={(event)=>{
            if (validate(event) && event.code==="Enter" && onValidate){
                onValidate(/*{target:{value:value}}*/)
            }
        }}
        
        >
            {props.children}
        </TextField>
    )
}