import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Badge, IconButton } from '@mui/material';
import styled from '@emotion/styled';

function StepIcon({value, size}) {
    
  const StyledBadge = styled(Badge)(() => ({
      '& .MuiBadge-badge': {
        right: 0,
        top: -1,
        width:size,
        height:size,
      },
    }));

  return (
      <IconButton disabled>
          <StyledBadge badgeContent={`${value}`} color="primary" />
      </IconButton>
  );
}


export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      
      {
        props.value === null || props.value === undefined ?
            <StepIcon value={props.id} size={props.size}/>
        :
        props.value === 0 ? 
            <CircularProgress size={props.size}/>
        :
        props.value > 0 && props.value < 100 ? 
            <>
                <CircularProgress variant="determinate" value={props.value} size={props.size}/>
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}>
                    <Typography variant="caption" 
                                component="div" 
                                sx={{fontSize:props.size/2}} 
                                color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </>
            
        :
        props.value === -1 ?
            <ErrorIcon color="error"/> 
        :
        props.value === 100 ?
            <CheckCircleIcon color="success"/> 
        :
            <ErrorIcon color="warning"/> 
      }
    </Box>
  );
}

// CircularProgressWithLabel.propTypes = {
//     /**
//      * The value of the progress indicator for the determinate variant.
//      * Value between 0 and 100.
//      * @default 0
//      */
//     value: PropTypes.number.isRequired,
//     size: PropTypes.number.isRequired,
// };

