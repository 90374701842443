import { 
    TextField,
    Button,
    Box,
    DialogContent,
    DialogContentText
} from "@mui/material"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import UndoIcon from '@mui/icons-material/Undo';

import { useEffect, useState } from "react"

/*************************************************************************************************************
 *     5)   Note                                                                                             *
 *************************************************************************************************************/
export function NoteStep({scenario, setScenario, describe}) {

    const [editing, setEditing] = useState(false)

    const handleTextChange = function(evt){
        setScenario({...scenario,  note:evt.target.value  })
    }

    const handleReset = () => {
        setEditing(false)
        setScenario({...scenario, note: describe()})
    }

    const handleEdit = () => {
        setEditing(true)
    }

    useEffect(() => {
        handleReset()
    }, [scenario.activeStep]) //eslint-disable-line

    useEffect(() => {
    }, [editing])


    return <>
        <DialogContent>
            <DialogContentText component="div">
                Write a note to describe this simulation                
            </DialogContentText>    
            
            <TextField
                multiline
                rows={10}
                disabled={!editing}
                variant="outlined"
                style={{ width: "100%", margin:5 }}
                value = {scenario.note}
                onChange={handleTextChange}
            />
            <br />

            <Box sx={{marginLeft:1}}>
            {
                editing ?
                <Button variant="outlined" color="warning" startIcon={<UndoIcon />} onClick={handleReset}>
                    Reset
                </Button>
                :
                <Button variant="contained" color="primary" startIcon={<DriveFileRenameOutlineIcon />} onClick={handleEdit}>
                    Edit
                </Button>
            } 
            </Box>
        </DialogContent>

        
        
    </>
}