
import {TextField} from "@mui/material"
import { getUserName } from "../../../utils/const";


const Username = (props) =>{

    return (<TextField 
        label= "username"
        defaultValue={getUserName()}
        InputProps={{
            readOnly: true,
        }}
        variant="standard" />)

}
export default Username;