import { useContext } from "react"
import Typography from "@mui/material/Typography"
import {Stack} from "@mui/material"
import DigitalTwinContext from "./DigitalTwinContext"
import { UploadGeodata } from "../widgets/UploadGeodata"

const UploadOtherLayersPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)
    
    const handleUploadZip = (event) => {
        setParams({...params, zip: event.filename})
    }

    return (<>
        <Stack spacing={2}>
          <Typography>Add a zip file with additional raster layers</Typography>
             
          <UploadGeodata 
            onUpload={handleUploadZip}
            datatype="raster" 
            />

        </Stack>
    </>)
}
export default UploadOtherLayersPanel;