import React from "react";
import {IconButton, Grid, Tooltip} from "@mui/material/"
import {useNavigate} from "react-router-dom"
import Paths from "../utils/paths";
import {ArrowBack} from '@mui/icons-material';


export const NewProjectTools = () => {

    let navigate = useNavigate()

    const handleBack = () => {
        navigate(Paths.MY_PROJECTS)
    }

    return (

        <Grid container justifyContent="flex-end" columnSpacing={3} >

            <Grid item>
                <Tooltip title="Back to My Projects">
                    <IconButton onClick={handleBack} variant="contained" color="primary"> 
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
            </Grid>
           
        </Grid>

    )
}