import React, { useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle}   from '@mui/material';
import { useEvent } from '../utils/events';

export default function UserProfileDialog() {
    
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  
  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };


  const handleClose = () => {
    setOpen(false);
  };

  useEvent("dialog:userProfile", handleClickOpen)


  return (
    <>
      
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">User profile</DialogTitle>
        
        <DialogContent dividers={true}>
         
            {/* contente here */}
     
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>

      </Dialog>
    </>
  );
}
