import React, { useContext } from 'react';
import {Typography, Stack, Alert} from "@mui/material"
import EedemButton from "../widgets/EedemButton"
import DigitalTwinContext from './DigitalTwinContext';

const DownloadInfiltrationPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)

    const setJid = (jids) => {
      setParams({...params, ir_progress: 0, ir_jid: jids[0]})
    }

    return (
      <Stack spacing={2}>
          <Typography>Download the layer about infiltration rate</Typography>
          {
            params.infiltration_error ? 
            <Alert severity="error">{params.dtm_error}</Alert>
            :
            <></>
          }
          <EedemButton 
            dataset="ESA/WorldCover/v100"
            band="Map"
            bbox={params.dtm}      
            onClick = {setJid}
            progress={params.ir_progress}
            cmap = "infiltration_rate"
          >
            Download
          </EedemButton>
      </Stack>
    )
}
export default DownloadInfiltrationPanel;
  