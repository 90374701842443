import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import DownloadInfiltrationPanel from "./DownloadInfiltrationPanel"
import UploadInfiltrationPanel from "./UploadInfiltrationPanel"

const InfiltrationStep = () => {

  const [mode, setMode] = useState("download")
  
  const handleChange = (event) => {
    setMode(event.target.value)
  };


  return (
    <FormControl>
      <RadioGroup row onChange={handleChange} defaultValue="download">
        <FormControlLabel value="download" control={<Radio />} label="Download"  />
        <FormControlLabel value="upload"   control={<Radio />} label="Upload" />
      
        { 
          (mode === "download") ?
            <DownloadInfiltrationPanel/> 
            :
            <UploadInfiltrationPanel />
        }

      </RadioGroup>
    </FormControl>
  );
}
export default InfiltrationStep;
