import { getToken, getUserName } from "./const";

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthorizationError";
  }
}

export function handleFetchErrors(response) {
  if (!response.ok) {
    if (response.status === 401) {
      throw new AuthorizationError();
    }
  }
  return response;
}

/**
   * Coordinates obtained from provider (nominatim.openstreetmap.org) are in a format (y,Y,x,X) while the required format for
   * drawing a bbox on openlayer map is (x,y,X,Y). 
   * @param {Array} coords Coordinates in format (y,Y,x,X)
   * @returns {Array} Coordinates in openlayers format: (x,y,X,Y)
   */
 export function convertCoordsForOLMap(coords) {
  return [coords[2], coords[0], coords[3], coords[1]]
}

/**
 * Coordinates obtained from provider (nominatim.openstreetmap.org) are in a format (y,Y,x,X) while the required format for
 * drawing a bbox on openlayer map is (x,y,X,Y). 
 * @param {Array} coords Coordinates in eedem/nominatim format (x,y,X,Y)
 * @returns {Array} Coordinates in openlayers format (y,Y,x,X)
 */
export function convertCoordsForEedem(coords) {
  return [coords[1], coords[3], coords[0], coords[2]]
}

// export const getProjectDir = (project, relative) => {
//   return ((relative) ? __drive__ : `${project.domain}`) + `/projects/${project.username}/${project.projectname}`
// }

export const isLogged = ()=>getUserName() && getToken()


export const MAX_PIXELS = 1e8 //10000 * 10000

/**  
  @param {array} extent Area in m²
  @param {int} max_pixels Max number of pixels allowed. 
  @param {int} dataset_resolution Resolution in pixel per meter (optional - default is 1)
  @returns True if the extension is too wide. Computes the number of pixels of the area according to the
  dataset resolution. If this value is overpassed the maximum extent is considered to be non valid. 
*/
export const isExtentValid = (area, max_pixels, dataset_resolution = 1) => {
    if (!area || isNaN(area)) {
      throw new Error("Provide a valid number area.\nIsNan: ", isNaN(area), ".")
    }
    if (!max_pixels && max_pixels < 0)
      throw new Error("Provide a valid number as maximum number of pixels")

    let nPixels = Math.round(area / dataset_resolution)
        
    // !DEBUG
    // console.log(`DEBUG
    // --------------------------------
    // extent:       ${area} m²
    // resolution:   ${dataset_resolution} ppm
    // nPixels:      ${nPixels}
    // max pixels:   ${MAX_PIXELS}
    // valid:        ${nPixels <= max_pixels ? "yes":"NO"}
    // --------------------------------`)
    return (nPixels <= max_pixels)
  }

  export function hex2rgb(hex) {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    let a = parseInt(hex.substring(6, 8), 16);
    return [r, g, b, a/255]
  }


  export const JSONDownload = (obj, filename="joson_file") => {
    
    var blob = new Blob([JSON.stringify(obj)], { type: "text/plain;charset=utf-8" });
    var url = window.URL || window.webkitURL;
    var a = document.createElement("a");

    a.download = filename.endsWith(".json") ? filename : filename + ".json";
    a.href = url.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}


