import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { fireEvent } from '../utils/events';
import { duplicateProject } from '../utils/requests';
import { 
  DeleteForever, 
  DriveFileRenameOutline, 
  ContentCopy, 
  MoreVert, 
  FolderZip,
  Share
} from "@mui/icons-material";
import { isDeveloper } from '../utils/http';


export default function ContextMenu({projectname}) {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  }


  const handleDuplicate = () => {
    duplicateProject(projectname)
      .then(() => fireEvent("event:reload"))              // calls the event -> directly apply the effect and reload the list
    handleClose()
  }

  const handleShare = () => {
    fireEvent("event:share", projectname) // calls the event -> show the dialog
    handleClose()
  }

  const handleDownload = () => {
    fireEvent("project:download", projectname) 
    handleClose()
  }


  const handleRename = () => {
    fireEvent("event:rename", projectname) // calls the event -> show the dialog
    handleClose()
  }


  const handleDelete = () => {
    fireEvent("event:delete", projectname) // calls the event -> show the dialog
    handleClose()
  }


  const handleClose = () => {
    setAnchorEl(null);
  };

  

  return (
    <div onContextMenu={handleOpen}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        onContextMenu={handleOpen}
      >
      <MoreVert /> 
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
            <MenuItem onClick={handleRename}>
                <ListItemIcon>
                    <DriveFileRenameOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDuplicate}>
                <ListItemIcon>
                    <ContentCopy fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleShare}
              disabled = {!isDeveloper()}
            >
                <ListItemIcon>
                    <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share with</ListItemText>
            </MenuItem>


            <MenuItem onClick={handleDownload}
              disabled = {!isDeveloper()}
            >
                <ListItemIcon>
                    <FolderZip fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download</ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteForever fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>

            </MenuList>
        </Menu>

      
    </div>
  );
}
