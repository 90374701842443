/**
 * linear_interpolation
 * @param {*} y2 
 * @param {*} y1 
 * @param {*} x2 
 * @param {*} x1 
 * @param {*} x 
 * @returns 
 */
export function linear_interpolation(y2, y1, x2, x1, x){
    return (x2 === x1) ? y1 : ( y1 + (y2 - y1) * (x - x1) / (x2 - x1) )
}

/**
 * 
 * @param {float} wd 
 * @param {float} area 
 * @param {fload} value_m2 
 * @param {Array<Float>} damage_coefficients 20 damage coefficients between 0 and 100
 * @returns 
 */
export function damage_function(wd, area, value_m2, damage_coefficients) {

    if (!wd || wd <= 0) return 0

    let df = damage_coefficients[damage_coefficients.length - 1] // assume the max damage
    let levels = [0, 0.25, 0.5, .75, 1, 1.25, 1.5, 2.0, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8]

    for (let i = 1; i < levels.length; i++) {  
        if (wd <= levels[i]) {
            df = linear_interpolation(damage_coefficients[i], damage_coefficients[i - 1], levels[i], levels[i - 1], wd)
            break
        }
    }
 
    return (df / 100) * area * value_m2
}

