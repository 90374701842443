import { useContext, useState} from "react";
import { addjob } from "../utils/addjob";
import strftime from "strftime";
import {Button} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import DigitalTwinContext from "../WizardComponents/DigitalTwinContext";
import { listify } from "../utils/strings";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import Tooltip from '@mui/material/Tooltip';
import { QgisContext, QgisProject } from "@valluzzi/safer-map";
//import md5 from "md5";

/**
 * @param {*} dataset 
 * @param {*} t_srs 
 * @param {*} band 
 * @param {*} cmap 
 * @param {*} progress 
 * @param {*} bbox 
 * @param {*} disabled 
 * @param {*} textWhenDisabled 
 */
export default function EedemButton({
    dataset, 
    band, 
    cmap, 
    progress,
    bbox, 
    disabled = false,
    textWhenDisabled = "",
    ...props
}) {
    
    const [project,] = useContext(QgisContext) 
    const [params, ] = useContext(DigitalTwinContext)
    const [, setEnabled] = useState(disabled)
    const Q = new QgisProject(project)

    const handleClick = (e) => {

        let datasets = dataset ? listify(dataset) : listify(params.dataset)
        let bands = band ? listify(band) : listify(params.band)
        const project_dir = Q.getProjectName()
        
        let t_srs = params.t_srs
        t_srs = t_srs.proj4 || t_srs
        let job_ids = []
        setEnabled(false)

        for (let j = 0; j < datasets.length; j++) {

            let dataset = datasets[j]
            let dataset_name = datasets[j].replaceAll("/","_")
            const ext = (["OSM/BUILDINGS", "ESA/WorldCover/v100"].includes(dataset)) ? "shp" : "tif"
            const HMS = strftime("%H%M%S")
            const jid = (new Date().getTime())+parseInt(Math.random()*1000000)

            if (dataset_name === "SAFER002_DEM"){
                // mokup for SAFER002
                dataset = "NASA/NASADEM_HGT/001"
            }
            if (dataset_name === "ESA_WorldCover_v100"){
                dataset_name = "inf_rate"
            } 
            
            const options = {
                jid:         jid,
                name:        `EEDEM${HMS}`,
                type:        "eedem",
                project:     project_dir,
                mode:        "local",
                out:         `${project_dir}/${dataset_name}_${HMS}.${ext}`,
                dataset:     dataset,
                band:        bands[j] || "elevation", 
                t_srs:       t_srs, 
                bbox:        bbox || params.bbox,    // context .bbox
                cmap:        cmap ? listify(cmap)[j] : "viridis"       // 
            }
        
            if (dataset_name === "SAFER002_DEM"){
                // mokup for SAFER002
                options.out= `${project_dir}/SAFER002_DEM_TEMP.tif`    
            }

            addjob(options)
            job_ids.push(options.jid)

        }
        props.onClick(job_ids)
    }


    const isLoading = (progress) => progress !== null && progress >= 0 && progress < 100
    

    return <>
        <Tooltip title={disabled ? textWhenDisabled : ""} followCursor={true}>
            <span>
                <Button
                    disabled = { isLoading(progress) || disabled}
                    variant = "contained"
                    onClick = {handleClick}
                    sx={{ mt: 1, mr: 1 }}
                    startIcon={ isLoading(progress) ? <CircularProgressWithLabel value={progress} size={24}/>: <DownloadIcon />}
                    color = "primary"
                >
                    {props.children}
                </Button>
            </span>
        </Tooltip>
    </>

}