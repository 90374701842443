import { useRef, useEffect } from "react"

/**
 * useInterval
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const tid = useRef();
  
  delay = (delay===undefined)?1000:delay
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback]);

  // Set up the interval.
  useEffect(() => {

    if (delay>=0) {
      tid.current = setInterval(savedCallback.current, delay)
      return () => {clearInterval(tid.current)}
    }else{
      //destroy the timeri delay <0
      clearInterval(tid.current);
    }
    
  }, [delay]);

}
