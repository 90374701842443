import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import * as ol from 'ol';
import * as sm from '@valluzzi/safer-map';
import {version} from '../../utils/version';

const Package = (props) => {
    return <ListItemButton>
                <ListItemIcon>
                    <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary={props.name} />
            </ListItemButton>
}


const SoftwareUpdatePanel = (props)=>{

    return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {/* <Package name={`safer-rain ${reactVersion}`}/> */}
            <Package name={`Saferplaces ${version}`}/>
            <Package name={`safer-map ${sm.version}`}/>
            <Package name={`OpenLayers ${ol.VERSION}`}/>
            
        </List>
}

export default SoftwareUpdatePanel;