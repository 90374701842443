/**
 * normpath
 */
 export function normpath(pathname){
	if (!pathname)
		return "";
	pathname = 	pathname.replace(/\\/gi,"/")       //  \\ ==> /
	pathname = 	pathname.replace(/\/\.\//gi,"/" )  // /./ ==> /
	pathname = 	pathname.replace(/:\/\//,":::").replace(/\/\//gi, "/").replace(/:::/,"://") // //==>/ except https://
	return pathname
}


/**
 * juststem 
 * juststem(pathname) es: juststem(/a/b/hello.txt) => hello
 */
export function juststem(pathname) {
	let fname = justfname(pathname)
	let arr = (fname.indexOf(".")>=0)?fname.split("."):[fname, ""];
	return arr.slice(0,arr.length-1).join(".");
}

/**
 * justfname
 * justfname(pathname) es: justfname(/a/b/hello.txt) => hello.txt
 */
export function justfname(filename) {
    return (""+filename).replace(/\\/g,'/').replace( /.*\//, '' );
}


/**
 * justpath
 * justpath(pathname) es: justpath(/a/b/hello.txt) => /a/b
 */
export function justpath(pathname) {
  	let arr = normpath(pathname).split("/");
  	return arr.slice(0,arr.length-1).join("/");
}

/**
 * justext
 * justext(pathname) es: justpath(/a/b/hello.txt) => txt
 */
export function justext(filename){
	if (!filename)return "";
    return (""+filename).substr((~-filename.lastIndexOf(".") >>> 0) + 2);
}
/**
 *  forceext
 * forceext(pathname) es: forceext("/a/b/hello.txt", "tif") => /a/b/hello.tif
 */
export function forceext(filename,ext){
	filename = (!filename)?"":filename
	if (justext(filename)==="") return filename+"."+ext
	let re = /(?:\.([^.]+))?$/
    return normpath(filename.replace(re,(ext)?"."+ext:""))
}
