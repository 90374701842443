import React from 'react';
import { Badge, Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { CircularProgressWithLabel } from '../widgets/CircularProgressWithLabel';
  
  export default function StepIcon({onClick = () => {}, count}) {
    
    const StyledBadge = styled(Badge)(() => ({
        '& .MuiBadge-badge': {
          right: 0,
          top: -1,
          size:12,
        },
      }));

    return (
        <IconButton disabled onClick={onClick}>
            <StyledBadge badgeContent={""+count} color="primary" />
        </IconButton>
    );
  }


export function MyStepper ({children, activeStep}) {

    const lineColor = "#bbb"

    const isActive = (item) => {
        return children.indexOf(item) === activeStep
    }

    return <>
        {
            children.map((step, i) => {
              
                return (    
                    
                    <Box key={i}>
                        <Box sx={{marginLeft:1}}>
                        
                            {/* Heading of the panel */}
                            <Grid container direction="row" alignItems="center">
                                {/* ICON - number/progress/done */}
                                <Grid item sx={{marginTop:1}}>
                                    {/* <MyCircularProgress value={step.props.progress} id={i+1}/> */}
                                    <CircularProgressWithLabel value={step.props.progress} size={24} id={i+1}/> 
                                </Grid>
                                
                                {/* Label */}
                                <Grid item sx={{marginLeft:1, marginTop:1}}>
                                    {step.props.label}
                                </Grid>

                            </Grid>

                        </Box>
                        
                        {/* content of the panel */}
                        <Box hidden={(!isActive(step))} 
                            sx={{
                                    borderLeft: (i < children.length) ? 1 : 0,
                                    paddingTop:0, paddingLeft:2, paddingRight:2, paddingBottom:0, // panel padding
                                    borderColor: lineColor,
                                    marginLeft:2
                                    }}> 
                                {step}
                        </Box>
                        
                        
                        {/* empty box - draws the line between a panel and the next one when is closed */}
                        <Box hidden={isActive(step)} 
                            sx={{
                                borderLeft: (i < children.length) ? 1 : 0, 
                                marginLeft:2, 
                                borderColor: lineColor
                            }}>
                            <br />
                        </Box>
                    </Box>
                )
            })
        }
    </>
}


// export function MyCircularProgress(props) {
//   return (
//     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      
//       {
//         // TODO: inserire logica nel componente MyCircularProgress
//         props.value === null || props.value === undefined ?
//             <StepIcon count={props.id} />
//         :
//         props.value === 0 ? 
//             <CircularProgress size={18}/>
//         :
//         props.value > 0 && props.value < 100 ? 
//             <>
//                 <CircularProgress variant="determinate" value={props.value} size={18}/>
//                 <Box
//                     sx={{
//                     top: 0,
//                     left: 0,
//                     bottom: 0,
//                     right: 0,
//                     position: 'absolute',
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     }}>
//                     <Typography variant="caption" component="div" sx={{fontSize:8}} color="text.secondary">
//                     {`${Math.round(props.value)}%`}
//                     </Typography>
//                 </Box>
//             </>
            
//         :
//         props.value === -1 ?
//             <ErrorIcon color="error"/> 
//         :
//         props.value === 100 ?
//             <CheckCircleIcon color="success"/> 
//         :
//             <ErrorIcon color="warning"/> 
//       }
     
      
      
//     </Box>
    
//   );
// }
// MyCircularProgress.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate variant.
//    * Value between 0 and 100.
//    * @default 0
//    */
//   value: PropTypes.number.isRequired,
// };




