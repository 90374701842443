import {useEffect} from "react"
import { 
    FormControlLabel,
    DialogContent, 
    Switch,
    DialogContentText, 
    FormHelperText,
    Alert
} from "@mui/material"
import { SelectFile } from "../widgets/SelectFile"


/*************************************************************************************************************
 *     2)   Infiltration                                                                                     *
 *************************************************************************************************************/
 export const GreenAmptStep = function({scenario, setScenario}) {

    useEffect(() => {
        if (!scenario.ga) {
            setScenario({...scenario, ir: ""})
        } 
        // eslint-disable-next-line 
    }, [scenario.ga])

    const handleGAChange = (event) => {
        setScenario({...scenario, ga: event.target.checked})
    }

    const handleIRChange = (event) => {
        let fileir = (event.target.value)
        setScenario({...scenario, ir: fileir})
    }

    const lithologyNotFound = !scenario.sand || !scenario.clay

    return (<>
            
            <DialogContent>
                <DialogContentText component="div">Take into account the infiltration with the GreenAmpt model.</DialogContentText>  
                <FormControlLabel 
                    label={"Enable GreenAmpt"} 
                    control={<Switch checked={scenario.ga} onChange={handleGAChange} disabled={lithologyNotFound}/>
                }/>
                
                <DialogContent>
                    <SelectFile 
                        disabled={!scenario.ga} 
                        type="vector" 
                        tags="infiltration_rate" 
                        value={scenario.ir} 
                        onChange={handleIRChange}  
                        />
                    <FormHelperText>Infiltration Rate</FormHelperText>

                    
                {
                    scenario.ga && !scenario.ir ?
                    <Alert severity="info">If GreenAmpt is active, you need to specify an infiltration layer. </Alert>
                    : null
                }
                {
                    !lithologyNotFound && !scenario.ga ?
                    <Alert severity="warning">Disabling GreenAmpt the information about the duration of the rainfall will be ignored</Alert>
                    : null
                }
                {
                    lithologyNotFound ?
                    <Alert severity="error">GreenAmpt model can be use only if lithology layers (sand, clay) are present in your project</Alert>
                    : null
                }
                </DialogContent>
            </DialogContent>
            </>)
}