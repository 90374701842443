import React, { useState, useEffect, useRef, useContext } from "react";
import {TextField, Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from '@mui/icons-material/Search';
import {convertCoordsForOLMap,} from "../utils/utils";
import { fetchSearchOptions } from "../utils/requests";
import { fireEvent } from "../utils/events";
import DigitalTwinContext from "./DigitalTwinContext";



const SearchCombo = ({onChange = () => {}}) => {
 
  const [options, setOptions] = useState([])                     
  const [inputValue, setInputValue] = useState("")                // value typed in the input
  const [requestedValue, setRequestedValue] = useState("")        // value to be requested for the query: after 2 sec the input value changes, that value is requested
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useContext(DigitalTwinContext)

  const lastTimeoutID = useRef(null)
  const cache = useRef({})

  /** This method is called some seconds after the input has changed */
  function handleRequestedValueChanged () {
      fetchSearchOptions(requestedValue)
      .then((response) => {
        let options = response.data.map((obj) => ({
          name: obj.display_name,
          key: obj.place_id,
          bbox: convertCoordsForOLMap(obj.boundingbox.map(Number)), // formato yYxX --> xyXY
        }))
        cache.current[requestedValue] = options;
        setOptions(options)
        setError(false)
        setLoading(false)
      })
      .catch(() => { setError(true); setLoading(false)}  )
  }

  const handleSelectOption = (event, selectedOption) => {
    if (selectedOption) {
      // if the ENTER key is pressed instead of selecting an option, the value will result not valid (a string is provided rather than an option object)
      // in this case we use the first option in the list
      if (!selectedOption.bbox || !selectedOption.name) { 
        selectedOption = options[0]
      }
      setParams({...params, bbox: selectedOption.bbox, location_name: selectedOption.name})
      onChange(selectedOption.bbox)
    }
  }
  
  /** Used when the text input changes. The value typed in the textfiled 
   * will become "requestedValue" if remains equal for 1 sec
   * and then used to fetch the options */
  const handleInputChanged = (newInputValue) => {
    setInputValue(newInputValue); 
    
    if (newInputValue.length >= 1) {                              // if the new input is longer than 1 character, search for new options
      setLoading(true)    
      if (cache.current[newInputValue]) {                         // if the new value is found in cache, get the already stored options
        setOptions(cache.current[newInputValue])
        setLoading(false)
      } else {
        if (lastTimeoutID.current) {                              // if there is already an ongoing timeout we clear it, since we want to only fetch data corresponding to last user input
          clearTimeout(lastTimeoutID.current); 
        }
        lastTimeoutID.current = setTimeout( () => setRequestedValue(newInputValue), 1100 )
      }
    } else {
      setOpen(false)
    }
  }


  useEffect(() => {
    if (requestedValue) {
      handleRequestedValueChanged()
    }
    //unmount della useEffect va fatto qui
    return () => clearTimeout(lastTimeoutID.current)
  }, [requestedValue]) //eslint-disable-line

 
  fireEvent("toggleDrawingTool", true) //* attiva il tool di disegno bbox
  


  return (
    <>
      <Autocomplete
        id="search-combo"
        filterOptions={(x) => x}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleSelectOption}
        options={options}
        getOptionLabel={(option) => {
            if (option.name) return option.name
            else return ""
        }}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        clearOnEscape
        disableClearable
        freeSolo
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => handleInputChanged(newInputValue)}
        renderOption = {(props, option) => {
          return (<li {...props} key={option.key}> 
            {option.name} 
          </li>)
        }}

        renderInput={(params) => {
          return (
              <TextField
                {...params}
                variant="standard"
                
                label="Area"
                error={error}
                helperText={error ? "Something went wrong" : ""}
                InputProps={{

                  onKeyDown: (e) => {
                    
                    if (e.key === 'Enter') {
                      if (!options || !options.length)
                        e.stopPropagation()
                      else {
                        handleSelectOption(e, options[0])
                      }
                        
                        
                    }
                  },

                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? 
                          <CircularProgress color="inherit" size={20} />
                          : 
                          <SearchIcon/>
                          // <Tutorial name={tutorials.DRAW_ON_MAP}>
                          //   <IconButton onClick={toggleDrawTool}>
                          //     {
                          //       drawActive ? 
                          //         <HighlightAltIcon color="primary"/> : <HighlightAltIcon/>
                          //     }
                          //   </IconButton>
                          // </Tutorial>
                      }
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
          );
        }}
      />
    </>
  );
}
export default SearchCombo;
