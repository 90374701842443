import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Stack, Checkbox, FormGroup, FormControlLabel} from '@mui/material/';

import selection_img from "../images/tutorial/selection.gif"
import infiltration_img from "../images/tutorial/infiltration.png"
import dtm_img from "../images/tutorial/dtm.png"
import buildings_img from "../images/tutorial/buildings.png"
import lithology_img from "../images/tutorial/lithology.png"

const fontSize = 14
const windowWidth = 350
const imgWidth = windowWidth - 20

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: windowWidth,
    fontSize: theme.typography.pxToRem(fontSize),
    border: '1px solid #dadde9',
  },
}));


export const tutorials = {
  DRAW_ON_MAP: "draw-on-map",
  DTM: "dtm",
  BUILDINGS: "buildings",
  LITHOLOGY: "lithology",
  INFILTRATION: "infiltration"
}


const contents = {
  
  "draw-on-map": {
    title: "Draw on map",
    description: <p>This tool allows you to draw on map. Holding <b>shift</b> (⇧) key + while left-click with mouse to track a rectangle</p>,
    image: selection_img,
    enabled: true,
  },

  "dtm": {
    title: "Digital Terrain Model (DTM)",
    description: <p>In this step you can download or upload a Digital Terrain Model (DTM). </p>,
    image: dtm_img, 
    enabled: true,
  },

  "buildings": {
    title: "Buildings",
    description: <>
      <p>Buildings are used in the simulation ect... In this step you can:</p>
      <ul>
      <li>Download the buildings file</li>
      <li>Upload a custom buildings file from your device.</li>
      </ul>
    </>,
    image: buildings_img, 
    enabled: true,
  },

  "lithology": {
    title: "Lithology",
    description: <p>The lithology consists of information about sand, silt and clay int the selected area.</p>,
    image: lithology_img, 
    enabled: true,
  },

  "infiltration": {
    title: "Geodata for infiltration",
    description: <>In this step you can upload: 
      <ul>
        <li>A set of vector files (.shp, .dbf, ...) <b>or</b></li>
        <li>A single .zip file containing a set of vector files (.shp, .dbf, ...)</li>
      </ul>
      </>,
    image: infiltration_img,
    enabled: true,
  },
}


/**
 * Shows a icon button. Clicking on it a tutorial will be shown inside a tooltip
 * @param {*} name The name of the tutorial to be shown 
 */
export const Tutorial = ({name, ...props}) => {

  const tutorial = contents[name]

  if (tutorial && tutorial.enabled) {
    return (      
      <HtmlTooltip
        title={
          <React.Fragment>
            <Stack spacing={3}>
              <Box>
                <Typography color="inherit"><b>{tutorial.title}</b></Typography>
                <img alt={tutorial.title} src={tutorial.image} width={imgWidth}/>
                {tutorial.description}
              </Box>
                
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Checkbox />
                  } 
                  label="Do not show this again"
                />
              </FormGroup>
                  
              
            </Stack>
          </React.Fragment>
        }>

        {
          props.children ?  
            props.children 
            : 
            <IconButton aria-label="help">
              <HelpIcon />
            </IconButton>
        }
      </HtmlTooltip>
    );
  } else {
    return <></>
  }
}


