import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Grid } from "@mui/material";
import { useEvent } from "../../utils/events";
import { hugevalue } from "../../utils/currency";

const DamageChart = () => {

  const um = "$"

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
      zoomType: "x",
      //height: "12%",
    },
    title: {
      text: "Damage",
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: `Damage (${um})`,
        },
      },
    ],
    xAxis: {
      type: "category",
      title: {
        text: "Layer",
      },
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    series: [{ name: "Damage", data: [], color: "rgba(255,0,0,0.75)" }],
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "top",
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    tooltip: {
      pointFormatter:function(){ return `Damage: <b>${hugevalue(this.y)}${um}</b>`},
    },
  });

  const handleDamageTool = (event) => {
    const totalDamages = event.detail.total_damages;
    const layernames = event.detail.layernames;

    console.log("DamageChart: ", layernames);

    setChartOptions({
      xAxis: {
        categories: layernames,
      },
      series: [
        {
          type: "column",
          name: "Damage",
          data: totalDamages,
          yAxis: 0,
          dataLabels: {
            enabled: true,
            formatter: function(){
              return `${hugevalue(this.y)}${um}`
            },
          },
        },
      ],
    });
  };

  useEvent("damage-tool:",handleDamageTool)

  return (
    <Grid container item>
      <Grid item md={12}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Grid>
    </Grid>
  );
};

export default DamageChart;
