
/**
 * hugevalue - format a number to a human readable string
 * @param {*} value 
 * @returns 
 */
export const hugevalue = (value, decimals=1)=>{

    if (value>1e9) return `${(value/1e9).toFixed(decimals)} B`
    else if (value>1e6) return `${(value/1e6).toFixed(decimals)} M`
    else if (value>1e3) return `${(value/1e3).toFixed(decimals)} k`
    else return `${value.toFixed(decimals)}`
}
