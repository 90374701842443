import React, { useState, useContext } from "react";
import { 
  Dialog, 
  Stepper, 
  Step, 
  StepLabel,  
  Box, 
  Typography, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Fab,
  TextField,
} from "@mui/material";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplyIcon from '@mui/icons-material/Reply';
import { QgisProject, QgisContext, Tools } from "@valluzzi/safer-map";
import { addjob } from "../utils/addjob";
import strftime from "strftime";

import { ExtremeSeaLevelStep } from "./ExtremeSeaLevelStep";
import { BeachAngleStep } from "./BeachAngleStep";
import { BarrierStep } from "./BarrierStep";
import { ModelStep } from "./ModelStep";
import { NoteStep } from "./NoteStep";
import { forceext } from "../utils/filesystem";
import { fireEvent } from "../utils/events";
import { len } from "../utils/strings";
import { ToolsContext } from "../widgets/ToolManager";
import { __drive__ } from "../utils/const";


export function ExtremeSeaLevelWizard(props) {
  
  const [project, ] = useContext(QgisContext);
  const [, setActiveTool] = useContext(ToolsContext)

  let Q = new QgisProject(project);
  
  const steps = {
    ESL: 1,
    BEACH: 2,
    BARRIERS: 3,
    MODEL: 4,
    NOTE: 5
  }
  
  const nSteps = len(steps)
  const randomId = strftime("%H%M%S")
  
  const [scenario, setScenario] = useState({
    pause: false,
    name: `COAST${randomId}`,
    esl: 1.2,
    r: false,
    tana: 0.002,
    tmax: 12,
    nl:   50,
    activeStep: steps.ESL,
    features: [],
    dmg: false,
    model: "safer_coast",
    mode: "local",
  });
  
  
  const runjob = function () {
    
    const project_dir = Q.getProjectDir(__drive__);

    let params = {
      name:       scenario.name, // "COAST"+ strftime("%M%S"),
      type:       "safer_coast_flooding",
      project:    Q.getProjectName(),
      filedtm:    Q.getDemPath(__drive__),
      filebld:    forceext( Q.getDemPath(__drive__),"bld.tif"), 
      fileobm:    Q.getBuildingsPath(__drive__),
      out:        `${project_dir}/WD_${scenario.name}.tif`,
      esl:        scenario.esl,
      r:          scenario.r,
      tana:       scenario.tana,
      filebarrier: `${project_dir}/barrier.shp`,
      barrier_selection: scenario.features.filter((f) => f.get("selected")).map((f) => f.getId()),
      tmax:       scenario.tmax, 
      nl:         scenario.nl, 
      dmg:        scenario.dmg,
      note:       scenario.note,
      model:      (scenario.model ==="untrim")? "untrim" : "safer_coast",
      mode:       scenario.mode  // "local" or "ecs" or "lambda"
    }

    addjob(params)
    fireEvent("simulation:start")
  }
  

  const closeWizard = () => {
      setActiveStep(null)
      props.handleClose()
  }

  
  /** Mette in pausa il wizard e attiva il tool per storage tanks */
  const handleDrawBarriers = () => {
    setActiveTool(Tools.BARRIER_SELECT)
    handlePause()
  }


  const handlePause = () => {
    setScenario({...scenario, pause: true})
  }


  const handleResume = () => {
    project.map.activateTool("Pan", true)
    setScenario({...scenario, pause: false})
  }
  
  
  const getDescription = () => {
    let esl = `an extreme sea level of ${scenario.esl}m.` + (scenario.r ? `\n- using an additional beach angle of tag(ɑ)=${scenario.tana}` : "")
    let model = `model '${scenario.model}'`
    let selectedFeatures = scenario.features.filter(feature => feature.get("selected"))
    let fids = selectedFeatures.map(feature => `\t- Barrier${feature.getId()} (${feature.get("height")}m)`).join("\n")
    let barriers = selectedFeatures.length ? `${selectedFeatures.length} barriers \n${fids}` : "no barriers"
    return "Coastal flooding with :\n- " + [esl, barriers, model].join("\n- ")
  }
  

  const setActiveStep = (n) => {
    setScenario({...scenario, activeStep: n})
  }
  

  const isStepOptional = (step) => {
    return [steps.BEACH, steps.BARRIERS, steps.MODEL ].includes(step);
  };
  
  
  const handleNext = () => {
    if (scenario.activeStep >= nSteps){
      runjob()
      closeWizard()
    } else {
      setActiveStep(scenario.activeStep  + 1)
    }
  }
  
  const handleNameChange = (event) => {
    setScenario({...scenario, name: event.target.value });
    return true;
  }
  

  const handleBack = () => {
    setActiveStep(scenario.activeStep - 1);
  }
  
  
  const panels = [
    <ExtremeSeaLevelStep scenario={scenario} setScenario={setScenario} />,
    <BeachAngleStep scenario={scenario} setScenario={setScenario} />, 
    <BarrierStep scenario={scenario} setScenario={setScenario} onPause={handleDrawBarriers} />, 
    <ModelStep scenario={scenario} setScenario={setScenario}/>, 
    <NoteStep scenario={scenario} setScenario={setScenario} describe={getDescription}/>]
    
    
    return (<>
      
      {
        scenario.pause ? 
        <Fab variant="extended" color="primary" 
          sx={{position:"absolute", zIndex:9999, left:20, top:60}} onClick={handleResume}>
        
          <ReplyIcon sx={{ mr: 1 }} />
          Back to wizard 
        </Fab> 
        : 
        <></>
      }
      <Dialog open={!scenario.pause}
        maxWidth="lg" 
        fullWidth
        PaperProps={{sx: {height: 750, width: 900}}}
      >
      
      <DialogTitle>
      Coastal scenario:
      <TextField sx={{marginLeft: 1}}
        value = {scenario.name}
        variant="standard"
        onChange={handleNameChange}
        helperText = "job name"
      />
      </DialogTitle>
      
      <DialogContent>
      <Box sx={{ width: "100%" }}>
      <Stepper activeStep={scenario.activeStep - 1}>
      
      {Object.keys(steps).map((label, index) => {
        const stepProps = {};
        const labelProps = {};
        if (isStepOptional(index)) {
          labelProps.optional = (
            <Typography variant="caption">Optional</Typography>
            );
          }
          
          return (
            <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
            );
          })}
          </Stepper>
          
          {panels[scenario.activeStep - 1]}
          
          </Box>
          </DialogContent>
          
          
          
          <DialogActions sx={{padding: 3}}>
            <Button onClick={closeWizard} color="error">Cancel</Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {(scenario.activeStep > 1) ? 
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>Back</Button> 
              : <></>
            }
            <Button 
              startIcon={scenario.activeStep === steps.NOTE ? <PlayArrowIcon /> : <></>}
              onClick={handleNext} 
              variant={scenario.activeStep === steps.NOTE ? "contained" : "outlined"}
            > 
            {scenario.activeStep === steps.NOTE ? 'Run' : 'Next'} 
            </Button>
          </DialogActions>
          
          </Dialog>
          
          </>);
        }
        
