import { useContext } from "react"
import Typography from "@mui/material/Typography"
import {Stack} from "@mui/material"
import DigitalTwinContext from "./DigitalTwinContext"
import { UploadGeodata } from "../widgets/UploadGeodata"


const UploadLithologyPanel = () => {

    const [params, setParams] = useContext(DigitalTwinContext)
    
    const handleUploadSand = (event) => {
        setParams({...params, sand: event.filename})
    }
  
    const handleUploadClay = (event) => {
        setParams({...params, clay: event.filename})
    }

    const handleSandFieldChanged = (field) => {
        setParams({...params, sand_fname: field})
    }

    const handleClayFieldChanged = (field) => {
        setParams({...params, clay_fname: field})
    }

    return (<>
        <Stack spacing={2}>
          <Typography>Select sand, clay shape files (.shp) from your device</Typography>
             
          
          <Typography variant="h6">Sand</Typography>
          <UploadGeodata 
            onUpload={handleUploadSand}
            onChangeField={handleSandFieldChanged}
            datatype="geodata" 
            geometry_type={["polygon", "multipolygon"]}
            fieldSelector
            cmap = "sand"
            />

          <Typography variant="h6">Clay</Typography>
          <UploadGeodata
            onUpload={handleUploadClay}
            onChangeField={handleClayFieldChanged}
            datatype="geodata" 
            geometry_type={["polygon", "multipolygon"]}
            fieldSelector
            cmap = "clay"
            />     
        </Stack>
    </>)
}
export default UploadLithologyPanel;