import React, { useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { fireEvent, useEvent } from '../utils/events';
import *  as requests from '../utils/requests';

export default function DeleteDialog() {

    const [projectname, setProjectName] = useState(null)
    const [open, setOpen] = useState(false);

    const handleOpenDialog = (event) => {
        setProjectName(event.detail)
        setOpen(true)
    }
   
    const handleDelete = (event) => {
        event.preventDefault();
        requests.deleteProject(projectname)
        .then(() => {
            fireEvent("event:reload")
            handleClose()
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    
    useEvent("event:delete", handleOpenDialog)

    return (
    
        <div>
        <Dialog open={open} onClose={handleClose} fullWidth sx={{maxWidth: '50%', margin:'10px auto'}}>
            <DialogTitle>Deleting project {projectname}.</DialogTitle>
            <DialogContent>
            The action is not reversible. Do yuo want to continue?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined"> Cancel </Button>
                <Button onClick={handleDelete} variant="contained" color="error"> Confirm </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
    }
