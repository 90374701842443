import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Grid, Box} from "@mui/material";
import Paths from '../utils/paths';
import * as requests from '../utils/requests';
import { preventDefault, handleEvent, useEvent, fireEvent } from "../utils/events";
import { AuthorizationError } from "../utils/utils";
import ProjectCard from '../widgets/ProjectCard';
import RenameDialog from "../dialog/RenameDialog";
import DeleteDialog from "../dialog/DeleteDialog";

import { CreateNewProjectButton } from "../widgets/CreateNewProjectButton";
import AppWrapper from "../widgets/AppWrapper";
import { user_get } from "../utils/http";
import { saveAs } from "file-saver";
import { justfname } from "../utils/filesystem";
import { useInterval } from "../utils/useinterval";

export const cardStyle = {minHeight: "250px"}


export const MyProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [reload, setReload] = useState(false)
    const navigate = useNavigate()
    sessionStorage.setItem("projectname", "")
    
    useEffect(()=>loadProjectList(), [reload])
    useEffect(()=>handleEvent("event:reload", ()=>{setReload(!reload)}), [reload])
   
    useEvent("project:download", (event) => {
      user_get("/api/project",{name:event.detail}).then(response => {
        if (response.data.status === "success") {
          let filezip = response.data.name
          saveAs(filezip, justfname(filezip))
        }
      })
    })

    const loadProjectList = () => {

      requests.listProjects()
        .then((response) => setProjects(response.data.data))
        .catch((e) => {
          if (e instanceof AuthorizationError) {
            console.log("AuthorizationError " + e)
          } else {
            console.log("Unknown error")
          }
        });
    }

    //Realoa the page every 10 seconds
    useInterval(() => {fireEvent("event:reload")}, 10000)

    return (
        <>
          <AppWrapper>

                <Box container="main" sx={{height:"100%", width:"90%", float:"right"}}>            
                
                  <Grid container 
                        sx={{display:"flex"}}
                        columns={{xs:3, sm: 4, md:6, lg:9, xl:12}}
                        width="80%"
                        spacing={2} 
                        direction="row"
                        justifyContent="left"
                        onContextMenu={preventDefault}
                        >

                    <Grid item xs={12}> 
                      <h1>MY PROJECTS</h1>
                    </Grid>
                    
                    {/* the first card is a button */}
                    <Grid item key={0} xs={3} sm={4} md={3} lg={3} xl={3}>
                        <CreateNewProjectButton onClick={() => {navigate(Paths.NEW_PROJECT)}}/>      
                    </Grid>
            
            
                    { // for each case study available for the user, create a card
                      projects.map( ( item, i) => 
                        <Grid item key={i+1} xs={3} sm={4} md={3} lg={3} xl={3}>
                          <ProjectCard projectname={item.project_name} date={item.last_update} disabled={!item.enabled}/>
                        </Grid>
                      )
                    }
              </Grid>
              </Box>

  

            <RenameDialog />
            <DeleteDialog />  

          </AppWrapper>
        </>
      );
}