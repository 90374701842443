import { Buffer } from 'buffer';

// export function nowarn(obj) {   !!use instead //eslint-disable-line
//   return true;
// }

export function isNumber(value) {
  //return !isNaN(Number(obj))
  return isFinite(value);
}

export function isArray(obj) {
  return Array.isArray(obj);
}

export function isString(obj) {
  return typeof obj === "string";
}

export function isObject(obj) {
  return typeof obj === "object" && !Array.isArray(obj);
}

export function isBoolean(obj) {
  if (typeof obj === "boolean") return true;
  return (
    typeof obj === "string" &&
    (obj.toLowerCase() === "true" || obj.toLowerCase() === "false")
  );
}

export function len(obj) {
  if (obj === undefined || obj === null) return 0;

  if (isString(obj) || isArray(obj)) return obj.length;

  if (isObject(obj) && obj.getLength) return obj.getLength();

  if (isObject(obj) && Object.keys) return Object.keys(obj).length;

  if (isObject(obj) && !Object.keys) {
    var keys = [];
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) keys.push(prop);
    } //end for
    return keys.length;
  }
  return 0;
}

export function lower(text) {
  return typeof text === "string" || text instanceof String
    ? text.toLowerCase()
    : "";
}

export function rgba2hex(rgba, opacity){
    
  if (rgba===undefined){
    rgba = [0,0,0,255]
  }else if (isString(rgba) && rgba.startsWith("#")){
    return rgba
  }else if (isString(rgba) && rgba.includes(",")){
    rgba = rgba.split(",")
  } 
  // override opacity
  rgba[3] = (rgba.length>3)?rgba[3]:255
  rgba[3] = (opacity===undefined)?rgba[3]:parseInt(opacity*255)

  // to_hex
  let res="#"
  for(let j=0;j<rgba.length;j++){
    let hx = Number(rgba[j]).toString(16)
    res+=(hx.length%2===0)?hx:"0"+hx
  }

  return res
}//end function

export function propertyWidth(value, um) {
  value = Number(value);
  value = value <= 0 ? 0.26 : value;
  if (lower(um) === "mm") {
    return "" + (value / 10).toFixed(2) + "cm";
  } else if (lower(um) === "pixel") {
    return "" + value + "px";
  } else if (lower(um) === "pt") {
    return "" + value + "pt";
  }
  return "" + value + "px";
}

export function pixels(value, um, decimals) {
  um = lower(um);
  value = Number(value);
  decimals = decimals === undefined ? 0 : decimals;
  if (um === "pixel") return value.toFixed(decimals);
  else if (um === "mm") return (value * 3.779).toFixed(decimals);
  return -1;
}

/**
 *  listify
 */
export function listify(text, c, removeEmpty) {
  if (!text) return [];

  if (isString(text)) {
    c = c ? c : ",";
    text = text.split(c);
  }
  if (isArray(text)) {
    if (removeEmpty) {
      return text.filter(
        (item) => item !== "" && item !== undefined && item !== null
      );
    }

    return text;
  }

  return [text];
}

/**
 *  mapify
 */
export function mapify(text, sep) {
  let res = {};
  if (isString(text)) {
    sep = sep ? sep : ";";
    let items = text.split(sep);
    for (let j = 0; j < items.length; j++) {
      let name_value = items[j].split("=");
      let key = decodeURIComponent(name_value[0].trim());
      let value = decodeURIComponent(name_value[1]);
      res[key] = value;
    }
  } else if (isArray(text)) {
    let items = text;
    for (let j = 0; j < items.length; j++) {
      let key = items[j]["@k"];
      let value = items[j]["@v"];
      res[key] = value;
    }
  }
  return res;
}

export function b64encode( str ) {
  if (!str)return ""
  return Buffer.from(str).toString('base64')
}
  
export function b64decode( str ) {
    if (!str) return ""
    return Buffer.from(str, 'base64').toString('utf-8')
}
