// events.js

import { useEffect } from "react";

/**
 * Insert inside the body of the useEffect. 
 * @param String eventType 
 * @param {*} listener 
 */
const on = (eventType, listener)=> {
  document.addEventListener(eventType, listener);
}

/* insert inside the return of the useEffect 
 * @param String eventType 
 * @param {*} listener 
 */
const off = (eventType, listener)=> {
  document.removeEventListener(eventType, listener);
}

const fireEvent = (eventType, data) =>{
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

const preventDefault = (event)=>{
  event.preventDefault()
  event.stopPropagation()
  return false
}

/**
 * To be use with useEffects:
 * 
 * Es: useEffects(()=>handleEvent("my-event", myHandlerFunction),[])
 *
 * @param {Tob } eventName 
 * @param {*} listener 
 * @returns 
 */
const handleEvent = ( eventName, listener)=>{
  
    on(eventName, listener)
    return ()=>{ 
        off(eventName, listener)  
    }
}

const useEvent = (eventName, listener)=>{
  //let deps = dependencies?dependencies:[]
  
  useEffect(()=>{
    //console.log(eventName,":", typeof(listener))
    on(eventName, listener)
    return ()=>{ 
        off(eventName, listener)  
    }
  }, [eventName, listener])
}


/**
 * Used to dispatch an event directly to an OpenLayer object. 
 * Example, used for trigger an event on a tool
 * ```
 * let tool = project.map.getTool("SectionDraw")
 * let newPoint = [0,1]
 * fireEventOnObject(tool, "change", newPoint)
 * ```
 * @param {Object} obj 
 * @param {String} eventType 
 * @param {Object} data 
 */
const fireOlEvent = (obj, eventType, data) => {
  obj.dispatchEvent({type: eventType, data: data})
}

export { on,  off, fireEvent, useEvent, handleEvent, preventDefault, fireOlEvent };
