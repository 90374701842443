import { useContext, useEffect, useState } from "react"

import { 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    DialogContentText, 
    Button,
    DialogContent,
    Grid,
    Stack
} from "@mui/material"

import { QgisContext } from "@valluzzi/safer-map";

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';




export const BarrierStep = function({scenario, setScenario, onPause}) {
    
    const featureLabel = "barrier"
    const value = "height"
    const unit = <>m</>
    
    /** true se tutte le features sono selezionate
    * in questo modo si tiene aggiornato il tasto "select all" */
    
    
    const [project, ] = useContext(QgisContext)
    const [features, setFeatures] = useState([])
    const [allSelected, setAllSelected] = useState(true)
    const areAllSelected = features.every((feature) => (feature.get("selected") || false))
    
    /** setta il valore "selected" a allSelected se non è presente 
    * nelle features (succede solo all'inizio) */
    for (let feature of features) {
        if (feature.get("selected") === undefined)
        feature.set("selected", allSelected)
    }
    
    /** Seleziona/deseleziona una singola feature */
    const selectFeature = (id, checked) => {
        
        features[id].set("selected", checked)
        setScenario({...scenario, features:features })
        setAllSelected(areAllSelected)
    }
    
    /** Seleziona/deseleziona tutte le features */
    const toggleAllCheckboxes = () => {
        let newFeatures = features
        newFeatures.forEach((feature) => {
            feature.set("selected", !allSelected)
        })
        setAllSelected(!allSelected)
    }

    
    /** 
     * Questo ha l'effetto di aggiornare l'elenco di 
     * features quando dalla pausa si torna al wizard 
     * Attenzione non effettare direttamente setScenario 
     * in una useEffect che si aggiorna ad ogni cambio di scenario
     * altrimenti si va in loop
     */
    useEffect(() => {
    
        project.map.getFeaturesOf("barrier").then(features => setFeatures(features))
      
    }, [scenario.activeStep, scenario.pause]) //eslint-disable-line
    
    /**
     * Su modifica dello stato interno "features" aggiorna lo stato "scenario.features"
     */
    useEffect(() => {
        setScenario({...scenario, features: features})
    }, [features]) //eslint-disable-line


    return <>
    { 
        <DialogContent>
        <DialogContentText component="div">
        Select barriers to simulate with
        </DialogContentText> 
        {
            <FormGroup>
            <Grid container sx={{
                border: 1,
                borderRadius: 3,
                padding:2, 
                mb: 2,
                display: "flex",
                height: 200,
                overflow: "hidden",
                overflowY: "scroll",
            }}
            >
            {
                features.length ? 
                features.map((feature) => {
                    return <Grid item lg={3} key={feature.getId()}>
                    <FormControlLabel 
                    control={<Checkbox 
                        checked={(feature.get("selected"))} 
                        onChange={(evt) => {selectFeature(feature.getId(), evt.target.checked)}} 
                        />} 
                        label={
                            <> {featureLabel}#{feature.getId()} ({feature.get(value)}{unit}) </>
                        }
                        />
                        </Grid>
                    })
                    : <DialogContentText component="div"><b>No {featureLabel} found</b></DialogContentText>
                }
                </Grid>      
                </FormGroup>
            }
            <Stack spacing={2} direction="row">
            
            <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AddCircleOutlineIcon />}
            onClick={onPause}>
            New 
            </Button>
            
            {
                // mostra il tasto "select all" solo se sono presenti features
                features.length ? 
                <Button 
                variant="outlined" 
                color={allSelected ? "warning" : "primary"}
                onClick={toggleAllCheckboxes}
                startIcon={allSelected ? <PlaylistRemoveIcon /> : <PlaylistAddCheckIcon/>}
                >
                {allSelected ? "Remove all" : "Use all"}
                </Button>
                : <></>
            }
            </Stack>
            
            </DialogContent>
        } 
        </>        
    }
    
