import { useState, useContext } from 'react';

import { 
    Dialog, 
    Box, 
    Fab, 
    Typography, 
    DialogTitle, 
    Stepper, 
    Step, 
    StepLabel,
    DialogContent, 
    DialogActions, 
    Button, 
    TextField
} from '@mui/material'

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { isNumber, len } from '../utils/strings'
import { forceext } from '../utils/filesystem'
import { addjob } from '../utils/addjob';
import { strftime } from '../utils/time';
import { fireEvent } from '../utils/events';
import { QgisProject, QgisContext, Tools } from '@valluzzi/safer-map';
import ReplyIcon from '@mui/icons-material/Reply';

import { RainFallStep } from "./RainFallStep";
import { GreenAmptStep } from "./GreenAmptStep";
import { StorageTankStep } from "./StorageTankStep";
import { ModelStep } from "./ModelStep";
import { NoteStep }  from "./NoteStep";

import {  ToolsContext } from '../widgets/ToolManager';
import { __drive__ } from '../utils/const';

export function RainFallWizard(props){

    
    const [project, ] = useContext(QgisContext)
    const Q = new QgisProject(project)
    const [, setActiveTool] = useContext(ToolsContext)

    const steps = {RAIN: 1, INFILTRATION: 2, STORAGE: 3, MODEL: 4, NOTE: 5}
    const nSteps = len(steps)
    const rainModes = {uniform: "uniform", local: "local", raster: "raster", safer003:"safer003"}
        
    const [scenario, setScenario] = useState({
        pause:      false,
        name:       strftime("RAIN%H%M%S"),
        rain:       100,
        rain_fname: "rain",
        ga:         false,
        duration:   1,
        tmax:       12,
        nl:         50,
        sand:       Q.getSandPath(__drive__),  //da verificare se è necessario
        clay:       Q.getClayPath(__drive__),
        ir:         "",
        activeStep: steps.RAIN,
        features:   [],   
        model:      "safer_rain",
        rain_mode:  rainModes.uniform, 
        dmg :       false, 
        mode:       "local" // local|lambda|ecs
    })

    const getDescription = () => {
        let rain = (scenario.rain_mode === rainModes.uniform) ? 
                    `uniform rain of ${scenario.rain}mm over all the domain` 
                    : (scenario.rain_mode === rainModes.local) ? 
                    `non-uniform rain spots `
                    : (scenario.rain_mode === rainModes.raster) ?
                    `non-uniform rain spots from raster file ${scenario.rain} (selected field '${scenario.rain_fname}')`
                    : "/"
        let ga = `GreenAmpt ${scenario.ga ? ("enabled\n- infiltration layer: " + scenario.ir) : "disabled"}`
        let duration = (scenario.ga || scenario.model === "untrim") ? `\n- duration ${scenario.duration}h` : ""
        let model = `model '${scenario.model}'`
        let selectedFeatures = scenario.features.filter(feature => feature.get("selected"))
        let fids = selectedFeatures.map(feature => `\t- Tank${feature.getId()} (${feature.get("v")}m3)`).join("\n")
        let tanks = selectedFeatures.length ? `${selectedFeatures.length} storage tanks\n${fids}` : "no storage tanks"
        return "Rain flooding with:\n- " 
                + [rain + duration, tanks, ga, model].join("\n- ")
    }
    

    const runjob = () => {

        const project_dir = Q.getProjectDir(__drive__)

        let params = {           
            name:        scenario.name,
            type:        "safer_rain_flooding",
            project:     Q.getProjectName(),
            filedtm:     Q.getDemPath(__drive__), //filebld,
            filebld:     forceext(Q.getDemPath(__drive__), "bld.tif"),  
            fileobm:     Q.getBuildingsPath(__drive__),
            out:         `${project_dir}/WD_${scenario.name}.tif` ,
            sand :       scenario.sand,
            clay :       scenario.clay,
            ir:          project_dir + "/" + forceext(scenario.ir, "shp"),
            rain:        (isNumber(scenario.rain)) ? scenario.rain : `${project_dir}/${scenario.rain}`,

            sand_fname:  Q.getSandFieldName(),
            clay_fname:  Q.getClayFieldName(),
            ir_fname:    Q.getInfiltrationFieldName(),
            rain_fname:  scenario.rain_fname,

            storage:     project_dir+"/storagetank.shp",
            storage_selection: scenario.features.filter((f) => f.get("selected")).map((f) => f.getId()),
            ga:          scenario.ga, 
            duration:    scenario.duration,
            tmax:        scenario.tmax, 
            nl:          scenario.nl, 
            dmg:         scenario.dmg,
            note:        scenario.note,
            model:       scenario.model,
            mode:        scenario.mode
        }      

        addjob(params)

        fireEvent("simulation:start")
    }

    
    const closeWizard = () => {
        setActiveStep(null)
        props.handleClose()
    }

    //TODO - queste due funzioni possono essere unificate!
    /** Mette in pausa il wizard e attiva il tool per diegnare rainfall spots */
    const drawRainFall = () => {
        setActiveTool(Tools.RAIN_SELECT)
        handlePause()
    }

    //TODO - queste due funzioni possono essere unificate!
    /** Mette in pausa il wizard e attiva il tool per storage tanks */
    const drawStorageTanks = () => {
        setActiveTool(Tools.STORAGE_TANK_SELECT)
        handlePause()
    }

    const handlePause = () => {
        setScenario({...scenario, pause: true})
    }

    const handleResume = () => {
        setActiveTool(Tools.PAN)
        setScenario({...scenario, pause: false})
      }


    const handleNameChange = (event) => {
        setScenario({...scenario, name: event.target.value})
    }

    /**
     * Set the active step of the wizard and activate the layer corresponding to the tool if needed
     * @param {*} n 
     */
    const setActiveStep = (step) => {
        setScenario({...scenario, activeStep: step})
    }

   
    const isStepOptional = (step) => {
        return [steps.INFILTRATION, steps.STORAGE, steps.MODEL].includes(step);
    };

    /** Sktip to the next step or run the job if the last step is reached */
    const handleNext = () => {
        if (scenario.activeStep >= nSteps){
            runjob()
            closeWizard()
        } else {
            setActiveStep(scenario.activeStep + 1)
        }
    }

    /** Go back to the previous step  */
    const handleBack = () => {
        setActiveStep(scenario.activeStep - 1);
    };
    

    const panels = [<RainFallStep scenario={scenario} setScenario={setScenario} options={rainModes} onPause={drawRainFall}/>, 
                    <GreenAmptStep scenario={scenario} setScenario={setScenario}/>, 
                    <StorageTankStep scenario={scenario} setScenario={setScenario} onPause={drawStorageTanks}/>,
                    <ModelStep scenario={scenario} setScenario={setScenario}/>, 
                    <NoteStep scenario={scenario}  setScenario={setScenario} describe={getDescription}/>]


    return (
        <>
        {
            scenario.pause ? 
            <Fab variant="extended" color="primary" sx={{position:"absolute", zIndex:9999, left:150, top:120}} onClick={handleResume}>
                <ReplyIcon sx={{ mr: 1 }} />
                    Back to wizard 
            </Fab> 
            :
            <></>
        }

        <Dialog 
            open={!scenario.pause} 
            maxWidth="lg" 
            fullWidth
            PaperProps={{sx: {height: 900, width: 900}}}
            >
            <DialogTitle>
                Pluvial scenario:
                <TextField sx={{marginLeft: 1}}
                    value = {scenario.name}
                    variant="standard"
                    onChange={handleNameChange}
                    helperText = "job name"
                />
            </DialogTitle>
            
            
            <DialogContent>
                <Stepper activeStep={scenario.activeStep - 1}>
                    {
                        Object.keys(steps).map((label, index) => {
                            const labelscenario = {};
                            if (isStepOptional(index)) {
                                labelscenario.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                    );
                            }
                            return (
                                <Step key={label}>
                                <StepLabel {...labelscenario}>{label}</StepLabel>
                                </Step>
                            );
                        })
                    }
                </Stepper>
                { panels[scenario.activeStep - 1] }
            </DialogContent>


            <DialogActions sx={{padding: 3}}>
                {/* BUTTON CANCEL*/}
                <Button onClick={closeWizard} color="error">Cancel</Button>
                
                <Box sx={{ flex: '1 1 auto' }} />

                {/* BUTTON BACK*/}
                {(scenario.activeStep > 1) ? 
                    <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>Back</Button> 
                    : <></>
                }

                {/* BUTTON NEXT*/}
                <Button 
                    startIcon={scenario.activeStep === steps.NOTE ? <PlayArrowIcon /> : <></>}
                    onClick={handleNext} 
                    variant={scenario.activeStep === steps.NOTE ? "contained" : "outlined"}
                    disabled={
                        (scenario.activeStep === steps.RAIN && !scenario.rain) ||
                        (scenario.activeStep === steps.INFILTRATION && (scenario.ga && !scenario.ir))
                    }
                    > 
                    {scenario.activeStep === steps.NOTE ? 'Run' : 'Next'} 
                </Button>
            </DialogActions>
       
        </Dialog>
        </>
   )
}
                

